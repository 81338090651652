<template>
  <div class="ticketRoutePageWrap">
    <m-layout-bar>
      <div class="ticketRouteWrap">
        <div class="banner">
          <img :src="require('../assets/image/ticket/banner.png')" alt />
        </div>
        <div class="content">
          <div class="tabsWrap">
            <router-link
              class="tabTitle trans"
              :class="{'active':activeIndex=='0'}"
              to="/ticketRoute/ticket"
              data-index="0"
              @click="clickhandle"
            >票价信息</router-link>
            <router-link
              class="tabTitle trans"
              :class="{'active':activeIndex=='1'}"
              to="/ticketRoute/route"
              data-index="1"
              @click="clickhandle"
            >推荐路线</router-link>
          </div>
          <div class="tabContent">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar";
export default {
  components: {
    [LayoutBar.name]: LayoutBar
  },
  data() {
    return {
      activeIndex: "0"
    };
  },
  created() {
    let active = this.$route.query.active;
    if (parseInt(active).toString() === "NaN") return;
    this.activeIndex = active;
  },
  methods: {
    clickhandle(e) {
      // 点击按钮，实现选中效果
      if (!e.currentTarget.getAttributeNode("data-index")) return;
      this.activeIndex = e.currentTarget.getAttributeNode("data-index").value;
      if (!this.activeIndex) {
        this.activeIndex = "0";
      }
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";

.ticketRouteWrap {
  background: $main-blue;
  border: 1px solid transparent;
  > .banner {
    > img {
      width: 100%;
    }
  }
  .content {
    width: 1400px;
    min-height: 1100px;

    margin: 0 auto;
    // border: 1px solid red;
    margin-bottom: 20px;
    // overflow: hidden;
    .tabsWrap {
      .tabTitle {
        width: 700px;
        height: 112px;
        line-height: 112px;
        display: inline-block;
        box-sizing: border-box;
        color: $main-bgOrange;
        background: $main-bgYellowLight;
        //
        text-align: center;
        border: 1px solid $main-bgYellow;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        font-size: 32px;
        font-weight: bold;
        &:hover {
          background: #fdeabf;
          // color: $main-white;
        }
      }
    }
    .tabContent {
      min-height: 980px;
      //   padding-top: 70px;
      background: $main-white;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      margin-bottom: 30px;
    }
  }
}

.active,
a:focus {
  background: $main-bgOrange !important;
  color: $main-white !important;
  // box-shadow: 0px 1px 39px 8px $main-gray;
  // box-shadow: 0px 1px 12px 1px $main-white inset;
}

a.hover {
  background: $main-orange !important;
  color: #fff !important;
}
</style>
